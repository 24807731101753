.menu-ul-list {
    display: inline;
}

.menu-title {
    margin: 30px;
}

.menu-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: lightgray;
}

.menu-container-item {
    padding: 10px 20px 10px 20px;
    margin-bottom: 30px;
    width: 400px;
    border: 4px solid rgba(123, 0, 0, 0.854);
    background-color: whitesmoke;
}

.menu-container-item-1 {
    min-height: 340px;
}

.menu-container-item-2 {
    min-height: 340px;
}

.menu-container-item-3 {
    min-height: 340px;
}

.menu-container-item-4 {
    min-height: 340px;
}

.menu-container-item-5 {
    min-height: 300px;
}

.menu-container-item-6 {
    min-height: 300px;
}

.menu-container-item-7 {
    min-height: 500px;
}

